
import { ref, inject, defineComponent } from "vue";
import { apiPost } from "@/api";
import { useStore } from "vuex";
import InputWrapper from "@/components/InputWrapper.vue";
import { Toast } from "@/utils/notify";
import { useRoute, useRouter } from "vue-router";
import { UserActionTypes } from "@/store/modules/user/actions";

export default defineComponent({
  name: "UnlockForm",
  components: { InputWrapper },
  setup: function () {
    const store = useStore();
    const formError = ref({});
    const passwordShow = ref(false);
    const route = useRoute();
    const router = useRouter();
    const formData = ref({
      email: store.getters.email,
      password: null,
      otp: undefined,
    });
    const requireOtp = ref(false);

    const rememberMe = ref(false);
    const loadingContext = inject("loginLoading") as (val: boolean) => void;

    async function handleLogin() {
      loadingContext(true);
      try {
        const response = await apiPost(
          "admin/user-account/sign-in",
          formData.value
        );
        if (response.status === 201) {
          requireOtp.value = true;
        } else {
          await store.dispatch(UserActionTypes.Login, response.data.data);
          await router.push((route.query.redirect as string) ?? "/");
        }
      } catch (e:any) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: e.message,
        }).then();
      }
      loadingContext(false);
    }

    return {
      formData,
      formError,
      rememberMe,
      passwordShow,
      requireOtp,
      handleLogin,
    };
  },
});
