import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "signin-inner my-3 my-lg-0 bg-white shadow-soft border border-light rounded p-4 p-lg-5 w-100 fmxw-500" }
const _hoisted_2 = { class: "text-center text-md-center mb-4 mt-md-0" }
const _hoisted_3 = { class: "mb-3 h3" }
const _hoisted_4 = { class: "d-flex justify-content-center align-items-center mt-4" }
const _hoisted_5 = { class: "font-weight-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UnlockForm = _resolveComponent("UnlockForm")!
  const _component_DefaultLoader = _resolveComponent("DefaultLoader")!

  return (_openBlock(), _createBlock(_component_DefaultLoader, { loading: $setup.isNowLoading }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "user-avatar large-avatar mx-auto mb-3 border-dark p-2" }, [
            _createElementVNode("img", {
              class: "rounded-circle",
              alt: "Image placeholder",
              src: _imports_0
            })
          ], -1)),
          _createElementVNode("h1", _hoisted_3, _toDisplayString($setup.store.getters.email), 1),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-gray" }, "Please input your password to continue.", -1))
        ]),
        _createVNode(_component_UnlockForm),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _cache[3] || (_cache[3] = _createTextVNode(" Not you? ")),
            _createElementVNode("a", {
              href: "javascript:void(0)",
              class: "font-weight-bold",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.logoutHandler && $setup.logoutHandler(...args)))
            }, " Logout ")
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}