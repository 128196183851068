import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-group mb-4"
}
const _hoisted_2 = {
  key: 1,
  class: "mb-4"
}
const _hoisted_3 = { for: "password" }
const _hoisted_4 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputWrapper = _resolveComponent("InputWrapper")!

  return (_openBlock(), _createElementBlock("form", {
    action: "#",
    class: "mt-4",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleLogin && _ctx.handleLogin(...args)), ["prevent"]))
  }, [
    (_ctx.requireOtp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "otp" }, "One time OTP", -1)),
          _createVNode(_component_InputWrapper, {
            errors: _ctx.formError.otp
          }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "input-group-text" }, [
                _createElementVNode("span", { class: "fas fa-user-shield" })
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.otp) = $event)),
                type: "number",
                class: "form-control px-1",
                placeholder: "******",
                id: "otp",
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.formData.otp]
              ])
            ]),
            _: 1
          }, 8, ["errors"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("label", _hoisted_3, "Your Password", 512), [
            [_vShow, false]
          ]),
          _createVNode(_component_InputWrapper, {
            errors: _ctx.formError.password
          }, {
            default: _withCtx(() => [
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "input-group-text" }, [
                _createElementVNode("span", { class: "fas fa-unlock-alt" })
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                type: _ctx.passwordShow ? 'text' : 'password',
                required: "",
                placeholder: "Password",
                class: "form-control px-1",
                id: "password",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.password) = $event))
              }, null, 8, _hoisted_4), [
                [_vModelDynamic, _ctx.formData.password]
              ]),
              _createElementVNode("span", {
                class: "input-group-text pointer",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.passwordShow = !_ctx.passwordShow))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass([_ctx.passwordShow ? 'text-danger' : '', "far fa-eye"])
                }, null, 2)
              ])
            ]),
            _: 1
          }, 8, ["errors"])
        ])),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "mt-3" }, [
      _createElementVNode("button", {
        type: "submit",
        class: "btn w-100 btn-dark"
      }, "Unlock")
    ], -1))
  ], 32))
}